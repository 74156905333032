@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700;800&display=swap');


* {
    font-family:'Rubik',sans-serif;
    font-size:12px;
  }

  /* Target the entire scrollbar */
::-webkit-scrollbar {
  width: 3px;
  height:3px; /* Width of the scrollbar */
}

/* Track (the area behind the thumb) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track color */
}

/* The scrollbar itself (the thumb) */
::-webkit-scrollbar-thumb {
  background: #888; /* Thumb color */
  border-radius: 3px; /* Rounded corners on the thumb */
}

/* On hover, the thumb color */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}