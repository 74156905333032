.SidebarItem {
  padding: 10px 16px;
  /* border-radius: 4px;	  */
  color: #696969;
  background-color: #fff;
  margin-bottom: 1rem;
  cursor: pointer;
  font-weight: 400;
  transition: all ease-in-out 0.2s;
  display: flex;
  align-items: center;
  position: relative;
}

/* .SidebarItem::after {
	content: '';
	position: absolute;
	right: -5%;
	height: 100%;
	width: 5px;
	background: transparent;
} */

.SidebarItem[data-active="true"] {
  background-color: #87aa4b;
  border-radius: 1px;
  /* color: #fff; */
  font-weight: 600;
  color: #fff;
}

.SidebarItem > span {
  margin-left: 10px;
}

.SidebarItem:hover {
  /* color: #fff; */
  font-weight: 600;
}

/* .SidebarItem[data-active='true'].SidebarItem::after {
	background: #8eaf55;
} */
