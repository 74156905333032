.Sidebar {
	height: calc(100vh - 43px);
	top: 43px;
	transition: all ease-in-out 0.4s;
	font-size:18px;
}

.OpenSidebar {
	transform: translateX(0);
	font-size:18px;
}

.CloseSidebar {
	transform: translateX(-100%);
	font-size:12px;
}
