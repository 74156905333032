.grayscale-animation {
    animation: bganimation 2s infinite; 
  }

  @keyframes bganimation {
    0% {
        filter: grayscale(0%);
        transform:scale(1);
    }
    25% {
        filter: grayscale(100%);
        transform:scale(1.3);
    }
    50% {
        filter: grayscale(0%);
        transform:scale(1.1);
    }
    25% {
        filter: grayscale(100%);
        transform:scale(1.3);
    }
    100% {
        filter: grayscale(0%);
        transform:scale(1);
    }
  }